import React, { Component, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

const NavbarCom = () => {
  const navigate = useNavigate();
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          console.log('oktaAuth token getInfo', info);
          setUserInfo(info);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const login = async () => navigate('/login/index');
  const logout = async () => {
    try {
      //console.log('Inside logout');
      await oktaAuth.signOut({
        postLogoutRedirectUri: window.location.origin,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <nav className='navbar navbar-marketing navbar-expand-lg bg-white navbar-light shadow '>
      <div className='col-12'>
        <div className='row text-primary py-3 mb-4 bg-white shadow shadow-bottom-sm d-none d-lg-flex nav-top'>
          <div className='col-sm'>
            <span className='mr-4'>
              <i className='far fa-clock mr-1'></i>
              Mon - Sun 0900 - 2100
            </span>
            <span className='mr-4'>
              <i className='fas fa-phone-alt mr-1'></i>
              +1-2345-3455-33
            </span>
            <span>
              <i className='far fa-envelope mr-1'></i>
              contact@beyondid.com
            </span>
          </div>
          <div className='col-12 col-sm-auto'>
            <a href='#' className='px-3'>
              <i className='fab fa-facebook-f'></i>
            </a>
            <a href='#' className='px-3'>
              <i className='fab fa-linkedin-in'></i>
            </a>
            <a href='#' className='px-3'>
              <i className='fab fa-twitter'></i>
            </a>
            <a href='#' className='px-3'>
              <i className='fab fa-instagram'></i>
            </a>
          </div>
        </div>
        <div className='row align-items-center d-none d-lg-flex mb-4'>
          <div className='col-auto'>
            <Link className='app-logo' to='/'>
              <img src='/img/logo.png' alt='' className='' />
            </Link>
          </div>
          <div className='col'>
            <div className='d-flex justify-content-end'>
              <div className='header-info'>
                <i className='far fa-check-circle'></i>
                <h4 className='text-primary font-weight-bold mb-0'>
                  Trusted by
                </h4>
                <p className='mb-0'>120,0000+ People</p>
              </div>
              <div className='header-info'>
                <i className='far fa-hospital'></i>
                <h4 className='text-primary font-weight-bold mb-0'>
                  Best Hospital
                </h4>
                <p className='mb-0'>CNN News</p>
              </div>
              <div className='header-info'>
                <i className='fas fa-award'></i>
                <h4 className='text-primary font-weight-bold mb-0'>
                  Number #1
                </h4>
                <p className='mb-0'>Clinic in New York</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className='row align-items-center justify-content-between'
          id='sticky_nav'
        >
          <div className='col'>
            <button
              className='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navbarSupportedContent'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='feather feather-menu'
              >
                <line x1='3' y1='12' x2='21' y2='12'></line>
                <line x1='3' y1='6' x2='21' y2='6'></line>
                <line x1='3' y1='18' x2='21' y2='18'></line>
              </svg>
            </button>
            <div
              className='collapse navbar-collapse'
              id='navbarSupportedContent'
            >
              <ul className='navbar-nav align-items-center'>
                <li className='d-lg-none'>
                  <a
                    type='button'
                    data-toggle='collapse'
                    data-target='#navbarSupportedContent'
                    aria-controls='navbarSupportedContent'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                  >
                    <i className='fas fa-times'></i>
                  </a>
                </li>
                <li class='nav-item'>
                  <Link class='nav-link' to='/'>
                    Home
                  </Link>
                </li>
                <li class='nav-item'>
                  <Link className='nav-link' to='/findadoctor'>
                    Find a Doctor
                  </Link>
                </li>
                <li class='nav-item'>
                  <Link className='nav-link' to='/paymyBill'>
                    Pay My Bill
                  </Link>
                </li>
                <li className='nav-item'>
                  <a
                    class='nav-link'
                    href='https://pharmacyhealth.beyondid-demo.com'
                    target='_blank'
                  >
                    ePharmacy
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    class='nav-link'
                    href='https://demo-beyondid.okta.com/home/demo-beyondid_mychartsamlapporchardinstance_1/0oa61yizt0yqcVbzm4x7/aln61ypzqwAdUsZlO4x7'
                    target='_blank'
                  >
                    MyChart
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-auto d-flex align-items-center'>
            <a data-toggle='modal' className='mr-3' data-target='#search-modal'>
              <i className='fas fa-search'></i>
            </a>
            {!authState ||
              (!authState.isAuthenticated && (
                <button className='btn btn-primary' onClick={login}>
                  Login
                </button>
              ))}
            {authState && authState.isAuthenticated && userInfo && (
              <div class='nav-item dropdown dropdown-user d-inline-block'>
                <a
                  class='px-3 btn btn-light nav-link dropdown-toggle'
                  href='#'
                  id='navbarDropdown'
                  role='button'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <i class='far fa-user-circle mr-2'></i>
                  {` ${userInfo.name}`}
                </a>
                <div
                  class='dropdown-menu dropdown-menu-right'
                  aria-labelledby='navbarDropdown'
                >
                  <Link className='dropdown-item' to='/account/profile'>
                    Profile
                  </Link>
                  <a class='dropdown-item' onClick={logout}>
                    Logout
                  </a>
                </div>
              </div>
              // <>
              //   <span id='welcome' className='mr-2'>
              //     {`Welcome, ${userInfo.email}`}
              //   </span>
              //   <button className='btn btn-primary' onClick={logout}>
              //     Logout
              //   </button>
              // </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavbarCom;
