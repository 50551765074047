import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import oktaSignInConfig from '../OktaSignInConfig';
// import '../../../src/css/style-custom.css';
// import '../../../src/css/style.css';

const EnrollmentForm = () => {
  const navigate = useNavigate();
  //   const widgetRef = useRef();

  //   useEffect(() => {
  //     if (!widgetRef.current) {
  //       return false;
  //     }

  //     const widget = new OktaSignIn({
  //       baseUrl: oktaSignInConfig.baseUrl,
  //       clientId: oktaSignInConfig.clientId,
  //       redirectUri: oktaSignInConfig.redirectUri,
  //       logo: 'https://img.freepik.com/premium-psd/instagram-logo-social-media-3d_304434-558.jpg?w=2000',
  //     });

  //     widget
  //       .showSignInToGetTokens({ el: widgetRef.current })
  //       .then(onSuccess)
  //       .catch(onError);

  //     return () => widget.remove();
  //   }, [onSuccess, onError]);

  return (
    <>
      <section className='section-login'>
        <div className='login-container'>
          <div className='login-panel-wrapper'>
            <form asp-action='enroll' method='post'>
              <div className='login-panel px-4'>
                <input
                  asp-for='Address'
                  type='hidden'
                  className='form-control'
                />
                <input
                  asp-for='DateOfBirth'
                  type='hidden'
                  className='form-control'
                />
                <input asp-for='City' type='hidden' className='form-control' />
                <input asp-for='State' type='hidden' className='form-control' />
                <input
                  asp-for='ZipCode'
                  type='hidden'
                  className='form-control'
                />
                <div className='credential-title mb-2'>
                  <span className=''>Enrollment Form</span>
                </div>
                <p className='text-muted mb-4'>
                  Please fill out the following fields to proceed to
                  registration.
                </p>
                <section className='row'>
                  <div className='col-md-12 form-group'>
                    <label>
                      First Name <span className='text-danger'>*</span>
                    </label>
                    <input
                      asp-for='FirstName'
                      type='text'
                      className='form-control'
                    />
                    <span
                      asp-validation-for='FirstName'
                      className='text-danger'
                    ></span>
                  </div>
                  <div className='col-md-12 form-group'>
                    <label>
                      Last Name <span className='text-danger'>*</span>
                    </label>
                    <input
                      asp-for='LastName'
                      type='text'
                      className='form-control'
                    />
                    <span
                      asp-validation-for='LastName'
                      className='text-danger'
                    ></span>
                  </div>
                  <div className='col-md-12 form-group'>
                    <label>
                      Username <span className='text-danger'>*</span>
                    </label>
                    <input
                      asp-for='Login'
                      type='text'
                      className='form-control'
                    />
                    <span
                      asp-validation-for='Login'
                      className='text-danger'
                    ></span>
                  </div>
                  <div className='col-md-12 form-group'>
                    <label>
                      Email Address <span className='text-danger'>*</span>
                    </label>
                    <input
                      asp-for='Email'
                      type='text'
                      className='form-control'
                    />
                    <span
                      asp-validation-for='Email'
                      className='text-danger'
                    ></span>
                  </div>
                  <div className='col-md-12 form-group'>
                    <label>
                      Password <span className='text-danger'>*</span>
                    </label>
                    <input
                      asp-for='Password'
                      type='password'
                      className='form-control'
                    />
                    <span
                      asp-validation-for='Password'
                      className='text-danger'
                    ></span>
                  </div>
                  <div className='col-md-12 form-group'>
                    <label>
                      Confirm Password <span className='text-danger'>*</span>
                    </label>
                    <input
                      asp-for='ConfirmPassword'
                      type='password'
                      className='form-control'
                    />
                    <span
                      asp-validation-for='ConfirmPassword'
                      className='text-danger'
                    ></span>
                  </div>
                  <div className='col-md-12'>
                    <div className='custom-control custom-checkbox my-3'>
                      <input
                        asp-for='{Consent}'
                        type='checkbox'
                        className='custom-control-input'
                        id='terms'
                      />
                      <label className='custom-control-label' for='terms'>
                        I have read and accept the{' '}
                        <a
                          href='#'
                          data-toggle='modal'
                          data-target='#exampleModal'
                        >
                          Consent
                        </a>
                        .<span className='text-danger'>*</span>
                      </label>
                      <div>
                        <span
                          asp-validation-for='Consent'
                          className='text-danger'
                        ></span>
                      </div>
                    </div>
                  </div>
                </section>
                <div>
                  <button className='btn btn-primary btn-block mb-3'>
                    Continue
                  </button>
                  <a
                    href='/login/index'
                    className='btn btn-outline-primary text-uppercase btn-block'
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <div
        className='modal fade'
        id='exampleModal'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5
                className='modal-title text-primary'
                id='exampleModalLabel'
              ></h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body bg-light'>
              <partial name='~/Views/Shared/Consent.cshtml' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnrollmentForm;
