import './App.css';
import Home from './components/Home';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Register from './components/Register/Register';
import oktaSignInConfig from './components/OktaSignInConfig';
import { LoginCallback, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import Auth from './components/Auth/Auth';
import PayAsGuests from './components/payAsGuest/PayAsGuest';
import ActivationCode from './components/activationCode/ActivationCode';
import PhoneVerificationForm from './components/Register/PhoneVerificationForm';
import UserRegistrationForm from './components/Register/UserRegistrationForm';
import EnrollmentForm from './components/Register/EnrollmentForm';
import RegistrationSuccessful from './components/Register/RegistrationSuccessful';
import PaymyBill from './components/paymyBill/paymyBill';
import Findadoctor from './components/findaDoctor/Findadoctor';
import NavbarCom from './components/NavbarCom';
import Footer from './components/Footer';
import PageNotFound from './components/PageNotFound';

import PrivateRoute from './PrivateRoute';
import Profile from './components/Login/Profile';
const auth = new OktaAuth(oktaSignInConfig);

const App = () => {
  const navigate = useNavigate();
  console.log('Inside App.js');

  const triggerLogin = () => {
    navigate('/login');
  };
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const customAuthHandler = async () => {
    const previousAuthState = auth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      // setAuthRequiredModalOpen(true);
    }
  };
  return (
    <Security
      oktaAuth={auth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <NavbarCom />

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login/callback' component={LoginCallback} />
        <Route path='/register' element={<Register />} />
        <Route path='/paymyBill' element={<PaymyBill />} />
        <Route path='/findadoctor' element={<Findadoctor />} />
        <Route path='/payasguest/index' element={<PayAsGuests />} />

        <Route path='/login/index' element={<Auth />} />
        <Route
          path='/Registration/success'
          element={<RegistrationSuccessful />}
        />
        <Route path='/registration/enroll' element={<EnrollmentForm />} />
        <Route
          path='/registration/register-with-code'
          element={<ActivationCode />}
        />
        <Route
          path='/registration/phone-verification'
          element={<PhoneVerificationForm />}
        />
        <Route path='/Registration' element={<UserRegistrationForm />} />

        <Route element={<PrivateRoute />}>
          <Route path='/account/profile' element={<Profile />} />
        </Route>
        <Route path='*' element={<PageNotFound />} />
      </Routes>
      <Footer />
    </Security>
  );
};

export default App;
