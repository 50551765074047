import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import axios from 'axios';

import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import oktaSignInConfig from '../OktaSignInConfig';
// import '../../../src/css/style-custom.css';
// import '../../../src/css/style.css';
import { phoneSchema, userSchema } from '../validation/Validation';
//import { createOktaUser } from '../../services/api';
import ApiService from '../../services/ApiService';
import { createOktaUser } from '../util/oktaService';

const getFirstName = () => {
  const names = ['Jane', 'James', 'Mark', 'Robert', 'Scott', 'Stefan'];
  const randomIndex = Math.floor(Math.random() * names.length);
  return names[randomIndex];
};

const getLastName = () => {
  const names = ['Twain', 'Cooper', 'Stone', 'Austen'];
  const randomIndex = Math.floor(Math.random() * names.length);
  return names[randomIndex];
};

const randomString = () => {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';
  let result = '';
  for (let i = 0; i < 4; i++) {
    const index = Math.floor(Math.random() * alphabet.length);
    result += alphabet[index];
  }
  return result;
};

const UserRegistrationForm = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const phoneNumber = new URLSearchParams(location.search).get('phoneNumber');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues: {
      firstName: getFirstName(),
      lastName: getLastName() + 'epicpatient' + randomString(),
      dateOfBirth: '1990-01-01',
      myChartId: '',
      gender: 'Male',
      phoneNumber: phoneNumber,
      password: '',
      confirmPassword: '',
      email: '',
      addressLine1: '444 Mission St 14th floor',
      addressLine2: '',
      city: 'San Francisco',
      state: 'CA',
      zipcode: '94105',
      checkbox: false,
    },
    validationSchema: userSchema,
    onSubmit: async (values) => {
      createOktaUser(values)
        .then((resp) => {
          navigate(`/Registration/success`);
        })
        .catch((err) => {
          setError(err);
          console.log('error is', err);
          console.log('Error While creating Okta user ', err);
        });
    },
    validateOnChange: false, // trigger validation on change
    validateOnBlur: false,
  });

  useEffect(() => {
    const { firstName, lastName } = values;
    if (firstName && lastName) {
      const myChartId = `${firstName.toLowerCase()}${lastName.toLowerCase()}`;
      // setValues((prevValues) => ({ ...prevValues, myChartId }));
      const email = `${firstName.toLowerCase()}.${lastName.toLowerCase()}@mailinator.com`;
      setValues((prevValues) => ({ ...prevValues, email, myChartId }));
    }
  }, []);

  return (
    <>
      <section className='section-login section-login-register'>
        <div className='login-container login-container-register mt-5'>
          <div className='login-panel-wrapper'>
            <div className='login-panel'>
              <div className='credential-title mb-4'>
                Welcome to BEYONDID Health System
                <br />
                <span className='font-weight-bold'>You're almost there!</span>
              </div>
              {error &&
              error.response &&
              error.response.data.errorSummary ===
                'Api validation failed: password' ? (
                <p style={{ color: 'red' }}>Password does not match criteria</p>
              ) : (
                error &&
                error.response &&
                error.response.data.errorCauses[0].errorSummary ===
                  'login: An object with this field already exists in the current organization' && (
                  <p style={{ color: 'red' }}>Username already exists</p>
                )
              )}
              <form asp-action='Index' onSubmit={handleSubmit}>
                <input
                  asp-for='{PhoneNumber}'
                  type='hidden'
                  className='form-control'
                />
                <h5 className='text-primary my-3'>Personal Information</h5>
                <section className='row'>
                  <div className='col-md-6 form-group'>
                    <label>
                      First Name <span className='text-danger'>*</span>
                    </label>
                    <input
                      asp-for='FirstName'
                      type='text'
                      className='form-control'
                      name='firstName'
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.firstName && errors.firstName && (
                      <p style={{ color: 'red' }}>{errors.firstName}</p>
                    )}
                    <span
                      asp-validation-for='FirstName'
                      className='text-danger'
                    ></span>
                  </div>
                  <div className='col-md-6 form-group'>
                    <label>
                      Last Name <span className='text-danger'>*</span>
                    </label>
                    <input
                      asp-for='{LastName}'
                      type='text'
                      className='form-control'
                      name='lastName'
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.lastName && errors.lastName && (
                      <p style={{ color: 'red' }}>{errors.lastName}</p>
                    )}
                    <span
                      asp-validation-for='FirstName'
                      className='text-danger'
                    ></span>
                  </div>
                  <div className='col-md-6 form-group'>
                    <label for=''>Date of Birth</label>
                    <input
                      asp-for='{DateOfBirth}'
                      type='text'
                      placeholder='YYYY-MM-DD'
                      className='form-control'
                      name='dateOfBirth'
                      value={values.dateOfBirth}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.dateOfBirth && errors.dateOfBirth && (
                      <p style={{ color: 'red' }}>{errors.lastName}</p>
                    )}
                    <span
                      asp-validation-for='FirstName'
                      className='text-danger'
                    ></span>
                  </div>
                  <div className='col-md-6 form-group'>
                    <label for=''>
                      Gender <span className='text-danger'>*</span>
                    </label>
                    <div className='d-flex'>
                      <div className='custom-control custom-radio custom-control-inline'>
                        <input
                          //asp-for='{Gender}'
                          id='customRadioInline1'
                          type='radio'
                          value='Male'
                          className='custom-control-input'
                          name='gender'
                          checked={values.gender === 'Male'}
                          onChange={handleChange}
                        />
                        <label
                          className='custom-control-label'
                          for='customRadioInline1'
                        >
                          Male
                        </label>
                      </div>
                      <div className='custom-control custom-radio custom-control-inline'>
                        <input
                          // asp-for='{Gender}'
                          type='radio'
                          value='Female'
                          className='custom-control-input'
                          id='customRadioInline2'
                          name='gender'
                          checked={values.gender === 'Female'}
                          onChange={handleChange}
                        />
                        <label
                          className='custom-control-label'
                          for='customRadioInline2'
                        >
                          Female
                        </label>
                      </div>
                      <div className='custom-control custom-radio custom-control-inline'>
                        <input
                          // asp-for='{Gender}'
                          type='radio'
                          value='Other'
                          className='custom-control-input'
                          id='customRadioInline3'
                          name='gender'
                          checked={values.gender === 'Other'}
                          onChange={handleChange}
                        />
                        <label
                          className='custom-control-label'
                          for='customRadioInline3'
                        >
                          Other
                        </label>
                      </div>
                    </div>
                    <span
                      asp-validation-for='Gender'
                      className='text-danger'
                    ></span>
                  </div>
                </section>
                <h5 className='text-primary my-3'>Login</h5>
                <section className='row'>
                  <div className='col-md-6 form-group'>
                    <label>
                      My Chart ID <span className='text-danger'>*</span>
                    </label>
                    <input
                      asp-for='{Login}'
                      type='text'
                      className='form-control'
                      name='myChartId'
                      value={values.myChartId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.myChartId && (
                      <p style={{ color: 'red' }}>{errors.myChartId}</p>
                    )}
                    <span
                      asp-validation-for='Login'
                      className='text-danger'
                    ></span>
                  </div>
                  <div className='col-md-6'></div>
                  <div className='col-md-6 form-group'>
                    <label>
                      Password <span className='text-danger'>*</span>
                    </label>
                    <input
                      asp-for='{Password}'
                      type={showPassword ? 'text' : 'password'}
                      className='form-control'
                      name='password'
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {values.password && ( // Render eye icon only if there is input text
                      <i
                        className={
                          showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'
                        }
                        style={{
                          position: 'absolute',
                          right: '35px',
                          top: '43px',
                          cursor: 'pointer',
                        }}
                        onClick={handleTogglePassword}
                      ></i>
                    )}
                    {touched.password && errors.password && (
                      <p style={{ color: 'red' }}>{errors.password}</p>
                    )}
                  </div>
                  <div className='col-md-6 form-group'>
                    <label>
                      Confirm Password <span className='text-danger'>*</span>
                    </label>
                    <input
                      asp-for='{ConfirmPassword}'
                      type={showConfirmPassword ? 'text' : 'password'}
                      className='form-control pr-5'
                      name='confirmPassword'
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span
                      asp-validation-for='ConfirmPassword'
                      className='text-danger'
                    ></span>
                    {values.confirmPassword && ( // Render eye icon only if there is input text
                      <i
                        className={
                          showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye'
                        }
                        style={{
                          position: 'absolute',
                          right: '35px',
                          top: '43px',
                          cursor: 'pointer',
                        }}
                        onClick={handleToggleConfirmPassword}
                      ></i>
                    )}
                    {errors.confirmPassword && (
                      <p style={{ color: 'red' }}>{errors.confirmPassword}</p>
                    )}
                  </div>
                </section>

                <h5 className='text-primary my-3'>Contact Information</h5>
                <section className='row'>
                  <div className='col-md-6 form-group'>
                    <label>
                      Mobile Number <span className='text-danger'>*</span>
                    </label>
                    <input
                      asp-for='{PhoneNumber}'
                      type='text'
                      disabled='disabled'
                      className='form-control'
                      value={phoneNumber}
                      //onChange={handleChange}
                    />
                    <span
                      asp-validation-for='PhoneNumber'
                      className='text-danger'
                    ></span>
                  </div>
                  <div className='col-md-6 form-group'>
                    <label>
                      Email Address <span className='text-danger'>*</span>
                    </label>
                    <input
                      asp-for='{Email}'
                      type='text'
                      className='form-control'
                      name='email'
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span
                      asp-validation-for='Email'
                      className='text-danger'
                    ></span>
                  </div>
                </section>

                <h5 className='text-primary my-3'>Your Address</h5>
                <section className='row'>
                  <div className='col-md-6 form-group'>
                    <label>
                      Address Line 1 <span className='text-danger'>*</span>
                    </label>
                    <input
                      asp-for='{Address}'
                      type='text'
                      className='form-control'
                      name='addressLine1'
                      value={values.addressLine1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.addressLine1 && (
                      <p style={{ color: 'red' }}>{errors.addressLine1}</p>
                    )}
                    <span
                      asp-validation-for='Address'
                      className='text-danger'
                    ></span>
                  </div>
                  <div className='col-md-6 form-group'>
                    <label>Address Line 2</label>
                    <input
                      asp-for='{Address2}'
                      type='text'
                      className='form-control'
                      name='addressLine2'
                      value={values.addressLine2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span
                      asp-validation-for='Address2'
                      className='text-danger'
                    ></span>
                  </div>
                  <div className='col-md-6 form-group'>
                    <label>
                      City <span className='text-danger'>*</span>
                    </label>
                    <input
                      asp-for='{City}'
                      type='text'
                      className='form-control'
                      name='city'
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.city && (
                      <p style={{ color: 'red' }}>{errors.city}</p>
                    )}
                    <span
                      asp-validation-for='City'
                      className='text-danger'
                    ></span>
                  </div>
                  <div className='col-md-6 form-group'>
                    <label>
                      State <span className='text-danger'>*</span>
                    </label>
                    <input
                      asp-for='{State}'
                      type='text'
                      className='form-control'
                      name='state'
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.state && (
                      <p style={{ color: 'red' }}>{errors.state}</p>
                    )}
                    <span
                      asp-validation-for='State'
                      className='text-danger'
                    ></span>
                  </div>
                  <div className='col-md-6 form-group'>
                    <label>
                      ZIP Code<span className='text-danger'>*</span>
                    </label>
                    <input
                      asp-for='{ZipCode}'
                      type='text'
                      className='form-control'
                      name='zipcode'
                      value={values.zipcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.zipcode && (
                      <p style={{ color: 'red' }}>{errors.zipcode}</p>
                    )}
                    <span
                      asp-validation-for='ZipCode'
                      className='text-danger'
                    ></span>
                  </div>
                </section>
                <section className='row'>
                  <div className='col-md-6 form-group'>
                    <div className='custom-control custom-checkbox my-3'>
                      <input
                        asp-for='{Consent}'
                        type='checkbox'
                        className='custom-control-input'
                        id='terms'
                        name='checkbox'
                        checked={values.checkbox}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label className='custom-control-label' for='terms'>
                        I have read and accept the{' '}
                        <a
                          href='#'
                          data-toggle='modal'
                          data-target='#exampleModal'
                        >
                          Consent
                        </a>
                        .<span className='text-danger'>*</span>
                      </label>
                      {touched.checkbox && errors.checkbox ? (
                        <p style={{ color: 'red' }}>{errors.checkbox}</p>
                      ) : null}
                      <span
                        asp-validation-for='Consent'
                        className='text-danger'
                      ></span>
                    </div>
                  </div>
                </section>
                <div className='text-right'>
                  <a
                    href='/login/index'
                    className='btn btn-outline-primary text-uppercase mr-2'
                  >
                    Cancel
                  </a>
                  <button className='btn btn-primary text-uppercase'>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <script src='https://cdn-zartan.s3.us-east-2.amazonaws.com/static/js/jquery.min.js'></script>

      {/* Modal */}

      <div
        className='modal fade'
        id='exampleModal'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5
                className='modal-title text-primary'
                id='exampleModalLabel'
              ></h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body bg-light'>
              {/* <partial name='~/Views/Shared/_TermsConditions.cshtml' /> */}
              <div className='login-container bg-light'>
                <div className='credential-title mb-4'>
                  <span>Consent</span>
                </div>
                <p>
                  You may download, store, display on your computer, view,
                  listen to, play and print materials that BeyondID publishes or
                  broadcasts on the site or makes available for download through
                  the site subject to the following: *(a) the materials may be
                  used solely for your personal, informational, non commercial
                  purposes; (b) the materials may not be modified or altered in
                  any way; and *(c) the materials may not be redistributed.
                </p>

                <p>
                  * Only BeyondID partners or vendors can use it for commercial
                  purposes, including redistribution.
                </p>

                <p>
                  Additional details regarding BeyondID's collection and use of
                  your personal information, including information about access,
                  retention, rectification, deletion, security, cross-border
                  transfers and other topics, is available in the BeyondID
                  Privacy Policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserRegistrationForm;
