import axios from 'axios';

export default class ApiService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  get(url) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.baseUrl}/${url}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  post(url, data) {
    let header = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'SSWS 00IKDoiN7b4ZRVUn34yNJM4LIMf2VyWTmjsGLkepND',
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .post(`${this.baseUrl}`, data, header)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getSDKToken(applicantId) {
    let data = { applicantId: applicantId };
    return new Promise((resolve, reject) => {
      this.post(process.env.REACT_APP_ONFIDO_SDK, data)
        .then((resp) => resolve(resp))
        .catch((error) => reject(error));
    });
  }
}
