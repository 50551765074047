import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import oktaSignInConfig from '../OktaSignInConfig';
// import '../../../src/css/style-custom.css';
// import '../../../src/css/style.css';
import { Components } from 'antd/lib/date-picker/generatePicker';
import { phoneSchema } from '../validation/Validation';
import { Link } from 'react-router-dom';

const PhoneVerificationForm = () => {
  const navigate = useNavigate();

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        phoneNumber: '',
        checkbox: false,
      },
      validationSchema: phoneSchema,
      onSubmit: (values) => {
        navigate(`/Registration?phoneNumber=${values.phoneNumber}`);
      },
      validateOnChange: false, // trigger validation on change
      validateOnBlur: false,
    });

  return (
    <>
      <section className='section-login section-login-register-phone'>
        <div className='login-container login-container-register-phone'>
          <div className='login-panel-wrapper'>
            <div className='login-panel'>
              <div className='credential-title'>
                Sign up to <br />
                <span>BeyondID Health System</span>
              </div>
              <p className='text-muted'>
                Entering your phone number will expedite the registration
                process. It will send you a text message to verify it’s you.
              </p>
              <form
                asp-action='phone-verification'
                method='post'
                onSubmit={handleSubmit}
              >
                <div className='form-group'>
                  <label>
                    Phone Number <span className='text-danger'>*</span>
                  </label>
                  <input
                    name='phoneNumber'
                    type='tel'
                    className='form-control'
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.phoneNumber && errors.phoneNumber ? (
                    <p style={{ color: 'red' }}>{errors.phoneNumber}</p>
                  ) : null}
                  <span
                    asp-validation-for='PhoneNumber'
                    className='text-danger'
                  ></span>
                </div>

                <div className='form-group custom-control custom-checkbox mt-3'>
                  <input
                    name='checkbox'
                    type='checkbox'
                    className='custom-control-input'
                    checked={values.checkbox}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='terms'
                  />

                  <label className='custom-control-label' for='terms'>
                    I have read and accept the{' '}
                    <a href='#' data-toggle='modal' data-target='#exampleModal'>
                      Terms and Conditions
                    </a>
                    .<span className='text-danger'>*</span>
                  </label>
                  {touched.checkbox && errors.checkbox ? (
                    <p style={{ color: 'red' }}>{errors.checkbox}</p>
                  ) : null}
                  <span
                    asp-validation-for='consent'
                    className='text-danger'
                  ></span>
                </div>

                <button className='btn btn-primary btn-block'>
                  Send verification code{' '}
                </button>

                <Link
                  to='/login/index'
                  className='btn btn-outline-primary btn-block btn-block mt-3'
                >
                  cancel
                </Link>
              </form>
              <Link
                to='/registration/enroll'
                className='btn btn-link btn-block skip-register-link mt-3 btn-sm'
              >
                Skip & Continue to Registration{' '}
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Modal */}

      <div
        className='modal fade'
        id='exampleModal'
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5
                className='modal-title text-primary'
                id='exampleModalLabel'
              ></h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body bg-light'>
              {/* <partial name='~/Views/Shared/_TermsConditions.cshtml' /> */}
              <div className='login-container bg-light'>
                <div className='credential-title mb-4'>
                  <span>Terms and Conditions</span>
                </div>
                <p>
                  You may download, store, display on your computer, view,
                  listen to, play and print materials that BeyondID publishes or
                  broadcasts on the site or makes available for download through
                  the site subject to the following: *(a) the materials may be
                  used solely for your personal, informational, non commercial
                  purposes; (b) the materials may not be modified or altered in
                  any way; and *(c) the materials may not be redistributed.
                </p>

                <p>
                  * Only BeyondID partners or vendors can use it for commercial
                  purposes, including redistribution.
                </p>

                <p>
                  Additional details regarding BeyondID's collection and use of
                  your personal information, including information about access,
                  retention, rectification, deletion, security, cross-border
                  transfers and other topics, is available in the BeyondID
                  Privacy Policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default PhoneVerificationForm;
