import React, { useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import oktaSignInConfig from '../OktaSignInConfig';
// import '../../../src/css/style-custom.css';
// import '../../../src/css/style.css';

const ActivationCode = () => {
  //   const widgetRef = useRef();

  //   useEffect(() => {
  //     if (!widgetRef.current) {
  //       return false;
  //     }

  //     const widget = new OktaSignIn({
  //       baseUrl: oktaSignInConfig.baseUrl,
  //       clientId: oktaSignInConfig.clientId,
  //       redirectUri: oktaSignInConfig.redirectUri,
  //       logo: 'https://img.freepik.com/premium-psd/instagram-logo-social-media-3d_304434-558.jpg?w=2000',
  //     });

  //     widget
  //       .showSignInToGetTokens({ el: widgetRef.current })
  //       .then(onSuccess)
  //       .catch(onError);

  //     return () => widget.remove();
  //   }, [onSuccess, onError]);

  return (
    <>
      <section className='section-login'>
        <div className='login-container'>
          <div className='login-panel px-2'>
            <div className='credential-title mb-4'>
              Your
              <br />
              <span>BeyondID Health System</span>
            </div>
            <div className='form-group'>
              <label>
                Email Address <span className='text-danger'>*</span>
              </label>
              <input type='text' className='form-control' />
            </div>
            <div className='form-group'>
              <label>
                Activation Code <span className='text-danger'>*</span>
              </label>
              <div className='form-row'>
                <div className='col'>
                  <input className='form-control' />
                </div>
                <div className='col'>
                  <input className='form-control' />
                </div>
                <div className='col'>
                  <input className='form-control' />
                </div>
              </div>
            </div>
            <div className='form-group'>
              <label>
                Date of Birth<span className='text-danger'>*</span>
              </label>
              <div className='form-row'>
                <div className='col'>
                  <input className='form-control' />
                </div>
                <div className='col'>
                  <input className='form-control' />
                </div>
                <div className='col'>
                  <input className='form-control' />
                </div>
              </div>
            </div>
            <div className='form-group'>
              <label>
                ZIP Code<span className='text-danger'>*</span>
              </label>
              <input type='text' className='form-control' />
            </div>
            <button className='btn btn-primary text-uppercase mb-3 btn-block'>
              Verify
            </button>
            <a
              href='/login/index'
              className='btn btn-outline-primary btn-block mb-3'
            >
              Cancel
            </a>
            <div className='text-center'>
              <a href='#' className='btn btn-link text-muted'>
                Need Help?
              </a>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default ActivationCode;
