import React, { Component } from 'react';
class Footer extends Component {
  render() {
    return (
      <>
        <section className='footer-section'>
          <div className='container-fluid px-0'>
            <div className='row no-gutters'>
              <div className='col-md-4'>
                <div className='h-100 px-4 py-5  bg-primary'>
                  <div className='row align-items-center'>
                    <div className='col-auto'>
                      <i className='fas fa-map-marker-alt'></i>
                    </div>
                    <div className='col pl-0'>
                      <h3 className='mb-0'>Get directions</h3>
                      <p className='mb-0 text-primary-light'>Find us on map</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div
                  className='h-100 px-4 py-5 '
                  style={{ background: '#05296f' }}
                >
                  <div className='row align-items-center'>
                    <div className='col-auto'>
                      <i className='fas fa-envelope-open-text'></i>
                    </div>
                    <div className='col pl-0'>
                      <h3 className='mb-0'>Contact & Appointment</h3>
                      <p className='mb-0 text-primary-light'>
                        Send us an email
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='h-100 px-4 py-5  bg-primary'>
                  <div className='row align-items-center'>
                    <div className='col-auto'>
                      <i className='fas fa-phone-alt'></i>
                    </div>
                    <div className='col  pl-0'>
                      <h3 className='mb-0'>Emergency Service</h3>
                      <p className='mb-0 text-primary-light'>
                        Call : +1-2345-3455-33
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className='footer pt-5 mt-auto bg-black footer-dark'>
          <div className='container'>
            <div className='row pb-5'>
              <div className='col-md-3'>
                {/* {process.env.REACT_APP_SERVER_URL} */}
                <div className='footer-brand'>
                  <img height='50' src='./img/logo.png' />
                </div>
                <div className='my-4'>
                  <p className='font-weight-bold mb-4'>
                    535 Mission St <br />
                    14th floor San Francisco,
                    <br /> CA 94105, USA
                  </p>

                  <div className='mb-3 '>
                    <i className='fas fa-phone-alt mr-2'></i>
                    +1-2345-3455-33
                  </div>
                  <div className='mb-5 '>
                    <i className='far fa-envelope mr-2'></i>
                    contact@beyondid.com
                  </div>
                </div>
                <div className='icon-list-social'>
                  <a
                    className='icon-list-social-link mr-3'
                    href='javascript:void(0);'
                  >
                    <i className='text-primary fab fa-instagram'></i>
                  </a>
                  <a
                    className='icon-list-social-link mr-3'
                    href='javascript:void(0);'
                  >
                    <i className='text-primary fab fa-facebook'></i>
                  </a>
                  <a
                    className='icon-list-social-link mr-3'
                    href='javascript:void(0);'
                  >
                    <i className='text-primary fab fa-github'></i>
                  </a>
                  <a
                    className='icon-list-social-link '
                    href='javascript:void(0);'
                  >
                    <i className='text-primary fab fa-twitter'></i>
                  </a>
                </div>
              </div>
              <div className='col-md-3'>
                <h4 className='font-weight-bold mb-4'>About Us</h4>
                <ul className='list-group list-group-flush'>
                  <li className='list-group-item px-0 py-2 border-0'>
                    <a href='/'>Our Mission & Values</a>
                  </li>
                  <li className='list-group-item px-0 py-2 border-0'>
                    <a href='/'>Leadership</a>
                  </li>
                  <li className='list-group-item px-0 py-2 border-0'>
                    <a href='/'>Transformation</a>
                  </li>
                  <li className='list-group-item px-0 py-2 border-0'>
                    <a href='/'>Awards</a>
                  </li>
                  <li className='list-group-item px-0 py-2 border-0'>
                    <a href=''>Diversity is Our Specialty</a>
                  </li>
                  <li className='list-group-item px-0 py-2 border-0'>
                    <a href='/'>Publications & Reports</a>
                  </li>
                  <li className='list-group-item px-0 py-2 border-0'>
                    <a href='/'>Policies & Procedures</a>
                  </li>
                </ul>
              </div>
              <div className='col-md-3 mt-5 mt-md-0'>
                <h4 className='font-weight-bold mb-4'>Our Services</h4>
                <ul className='list-group list-group-flush'>
                  <li className='list-group-item px-0 py-2 border-0'>
                    <a href='/'>Lung Diseases</a>
                  </li>
                  <li className='list-group-item px-0 py-2 border-0'>
                    <a href='/'>Orthopaedic</a>
                  </li>
                  <li className='list-group-item px-0 py-2 border-0'>
                    <a href='/'>Pharmacy</a>
                  </li>
                  <li className='list-group-item px-0 py-2 border-0'>
                    <a href='/'>Sport Injury</a>
                  </li>
                  <li className='list-group-item px-0 py-2 border-0'>
                    <a href='/'>Heart</a>
                  </li>
                  <li className='list-group-item px-0 py-2 border-0'>
                    <a href='/'>Dental Service</a>
                  </li>
                </ul>
              </div>
              <div className='col-md-3 mt-5 mt-md-0'>
                <h4 className='font-weight-bold mb-4'>Hospital Hours</h4>
                <ul className='list-group list-group-flush text-primary'>
                  <li className='list-group-item d-flex justify-content-between align-items-center font-weight-bold px-0'>
                    <span>
                      <i className='far fa-clock mr-2'></i> Monday-Friday
                    </span>
                    <span>08:00-20:00</span>
                  </li>
                  <li className='list-group-item d-flex justify-content-between align-items-center font-weight-bold px-0'>
                    <span>
                      <i className='far fa-clock mr-2'></i>Saturday
                    </span>
                    <span>08:00-18:00</span>
                  </li>
                  <li className='list-group-item d-flex justify-content-between align-items-center font-weight-bold px-0'>
                    <span>
                      <i className='far fa-clock mr-2'></i>Sunday
                    </span>
                    <span>08:00-18:00</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='py-4 bg-white shadow'>
            <div className='container'>
              <div className='row align-items-center'>
                <div className='col-md-6 small'>
                  Copyright &copy; BeyondID Health System
                </div>
                <div className='col-md-6 text-md-right small'>
                  &middot;
                  <a href='javascript:void(0);' className='mr-3'>
                    Privacy Policy
                  </a>
                  &middot;
                  <a href='javascript:void(0);'>Terms &amp; Conditions</a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
