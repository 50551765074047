import React, { useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import oktaSignInConfig from '../OktaSignInConfig';
import { Link } from 'react-router-dom';
const OktaSignInWidget = ({ onSuccess, onError }) => {
  const widgetRef = useRef();

  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }

    const widget = new OktaSignIn({
      baseUrl: oktaSignInConfig.baseUrl,
      clientId: oktaSignInConfig.clientId,
      redirectUri: oktaSignInConfig.redirectUri,
      logo: 'https://img.freepik.com/premium-psd/instagram-logo-social-media-3d_304434-558.jpg?w=2000',
      scopes: oktaSignInConfig.scopes,
    });

    widget
      .showSignInToGetTokens({ el: widgetRef.current })
      .then(onSuccess)
      .catch(onError);

    return () => widget.remove();
  }, [onSuccess, onError]);

  return (
    <section class='section-login'>
      <div class='login-container login-container-lg'>
        <div class='login-top-info'>
          <h6 class='mb-0 text-muted'>
            Looking for MyChart? You’re in the right place. <br />
            Log in to your BeyondID Health System account to access MyChart and
            more.
          </h6>
        </div>
        <div class='login-panel-wrapper'>
          <div class='login-panel'>
            <div ref={widgetRef} className='okta-login' />
          </div>
          <div id='login-register-panel' class='login-register-panel'>
            <p class='credential-title'>Don't have an account</p>
            <Link
              class='btn btn-block btn-primary mb-2 text-uppercase'
              to='/registration/phone-verification'
            >
              Create New Account
            </Link>
            <Link
              class='btn btn-block btn-outline-primary mb-5 text-uppercase'
              to='/registration/register-with-code'
            >
              Create with Activation code
            </Link>

            <p>Pay your bills without an account</p>
            <Link
              class='btn btn-block btn-primary text-uppercase'
              to='/payasguest/index'
            >
              Pay As Guest
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OktaSignInWidget;
