import React, { useState } from "react";
import { Switch } from "antd";
import "antd/dist/antd.css"
  
const ToggleSwitch = () => {

  const [toggle, setToggle] = useState(false)

const toggler =() =>{
  toggle ? setToggle(false): setToggle(true);
  console.log(toggle)
  if(toggle==false){
    console.log("I am Off. Hello!!!")
  }
}


  return (
    <div>
      <Switch onClick={toggler}/>
    </div>
  );
};
  
export default ToggleSwitch;