import { useEffect, useRef } from 'react';

import ApiService from '../../services/ApiService';

const apiService = new ApiService(
  'https://demo-beyondid.okta.com/api/v1/users?activate=true'
);

export const createOktaUser = (userValues) => {
  const user = {
    profile: {
      firstName: userValues.firstName,
      lastName: userValues.lastName,
      email: userValues.email,
      login: userValues.myChartId,
      gender: userValues.gender,
      mobilePhone: userValues.phoneNumber,
      birthDate: userValues.dateOfBirth,
      city: userValues.city,
      state: userValues.state,
      zipCode: userValues.zipcode,
      streetAddress: userValues.addressLine1,
    },
    credentials: {
      password: {
        value: userValues.password,
      },
    },
  };

  return apiService.post('api/v1/users', user);
};
