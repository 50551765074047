import React, { Component, useEffect, useState } from 'react';
// import "./Register.css";
import * as OnfidoSDK from 'onfido-sdk-ui/dist/onfido.min.js';
import 'onfido-sdk-ui/dist/style.css';
import ApiService from '../../services/ApiService';
import useInterval from '../util/interval';
import { useForm } from 'react-hook-form';
import ToggleSwitch from '../Togglebutton/ToggleSwitch';
import { Switch } from 'antd';

var applicationID = '';

export default function Register() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [vRequired, setValueRequired] = useState(true);
  const [sdkToken, setSDKToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [verifyState, setVerifyState] = useState({
    isComplete: undefined,
    checkId: '',
  });

  const [showOnfidoView, setShowOnfidoView] = useState(false);

  const {
    register,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
    },
  });

  const apiService = new ApiService(process.env.REACT_APP_BASEURL);

  const openOnfidoUI = (token) => {
    console.log('token --> ', token);
    setLoading(true);
    OnfidoSDK.init({
      token: token,
      containerId: 'onfido-mount',
      onComplete: function (data) {
        setLoading(false);
        console.log('everything is complete', JSON.stringify(data));
        startChecking();
      },
    });
  };

  //{"isError":true,"message":{"name":"OnfidoApiError","responseBody":{"error":{"type":"incomplete_checks","message":"Check cannot be created as there are other ongoing checks associated with this applicant.","fields":{}}},"statusCode":422,"type":"incomplete_checks","fields":{}}}
  const startChecking = () => {
    console.log('application id --> ', applicationID);
    const pData = { applicationId: applicationID };
    apiService
      .post(process.env.REACT_APP_ONFIDO_CHECK, pData)
      .then((resp) => {
        setVerifyState((prevState) => ({
          ...prevState,
          isComplete: false,
          checkId: resp.data,
        }));
      })
      .catch((err) => startChecking());
  };

  useInterval(
    () => {
      if (!verifyState.isComplete && verifyState.isComplete !== undefined) {
        checkStatus(verifyState.checkId);
      }
    },
    !verifyState.isComplete ? 10000 : null
  );

  const createOktaUser = () => {
    const user = {
      profile: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        login: email,
        onfidoApplicantId: applicationID,
        onfidoIdvStatus: 'true',
      },
      credentials: {
        password: {
          value: password,
        },
      },
    };

    apiService
      .post('api/create', user)
      .then((resp) => {
        console.log('User Created :', resp);
        alert(`User is created ${JSON.stringify(firstName)}`);
      })
      .catch((err) => console.log('Error While creating Okta user ', err));
  };

  const checkStatus = (data) => {
    apiService
      .post('api/status', data)
      .then((resp) => {
        console.log('respo -> ', resp);
        if (resp.data.checkStatus == 'complete') {
          setVerifyState((prevState) => ({
            ...prevState,
            isComplete: true,
            result: resp.data.checkResult,
          }));
          // alert(resp.data.checkResult);
          createOktaUser();
        }
      })
      .catch((err) => console.log('130 err -<> ', err));
  };

  useEffect(() => {
    if (firstName !== '' && lastName !== '') {
      let em = firstName + '.' + lastName + '@mailinator.com';
      let usern = firstName + '.' + lastName;
      setEmail(em);
      setUsername(usern);
      setValueRequired(false);
    } else if (firstName === '' || lastName === '') {
      setEmail('');
      setUsername('');
      setValueRequired(true);
    }
  }, [firstName, lastName]);

  const onSubmit = (data) => {
    const targetDiv = document.getElementById('divdiv');
    targetDiv.style.display = 'none';

    const pdata = {
      user: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: email,
      },
    };

    apiService
      .post(process.env.REACT_APP_ONFIDO_CREATE_USER, pdata)
      .then((resp) => {
        applicationID = resp.data.applicantId;
        apiService
          .getSDKToken(resp.data.applicantId)
          .then((res) => {
            showOnfidoView ? openOnfidoUI(res.data.sdkToken) : createOktaUser();
          })
          .catch((err) => console.log(err));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onToggleSwitch = (value) => {
    setShowOnfidoView(!showOnfidoView);
  };

  return (
    <React.Fragment>

      <section class='section-register'>
        <div class='login-container'>
          <div class='login-panel' style={{ zIndex: 100 }} id='divdiv'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div class=' row'>
                <div class='form-group col'>
                  <input
                    type='text'
                    class='form-control'
                    placeholder='First Name*'
                    id='fname'
                    name='firstName'
                    {...register('firstName', {
                      required: 'This is required',
                      onChange: (e) => {
                        setFirstName(e.target.value);
                      },
                    })}
                  />
                  <p>{errors.firstName?.message}</p>
                </div>

                <div class='form-group col'>
                  <input
                    type='text'
                    class='form-control'
                    placeholder='Last Name*'
                    id='lname'
                    name='lastName'
                    {...register('lastName', {
                      required: 'This is required',
                      onChange: (e) => {
                        setLastName(e.target.value);
                      },
                    })}
                  />
                  <p>{errors.lastName?.message}</p>
                </div>
              </div>

              <div class=' row'>
                <div class='form-group col-sm-6'>
                  <input
                    type='text'
                    class='form-control'
                    placeholder='Username*'
                    id='username'
                    name='username'
                    value={username}
                  />
                </div>
                <div class='form-group col-sm-6'>
                  <input
                    type='text'
                    class='form-control'
                    placeholder='Email*'
                    id='email'
                    name='email'
                    value={email}
                  />
                </div>
              </div>

              <div class=' row'>
                <div class='form-group col-sm-6'>
                  <input
                    type='password'
                    class='form-control'
                    placeholder='Password*'
                    id='password'
                    name='password'
                    {...register('password', {
                      required: 'This is required',
                      onChange: (e) => {
                        setPassword(e.target.value);
                      },
                    })}
                  />
                  <p>{errors.password?.message}</p>
                </div>
                <div class='form-group col-sm-6'>
                  <input
                    type='password'
                    class='form-control'
                    placeholder='Confirm Password*'
                    data-val='true'
                    data-val-required='cp is required.'
                    id='cpassword'
                    name='cpassword'
                    {...register('confirmPassword', {
                      validate: (value) => {
                        const { password } = getValues();
                        return password === value || 'Passwords should match';
                      },
                    })}
                  />
                  <p>{errors.confirmPassword?.message}</p>
                </div>
              </div>

              <button
                type='submit'
                class='btn btn-primary btn-block rounded'
                id='submit'
              >
                Submit
              </button>

              <span class='text-danger'></span>
              <input name='__RequestVerificationToken' type='hidden' />
            </form>
            <div class='text-center mt-4'>
              <Switch
                onChange={(e) => onToggleSwitch(e)}
                // checkedChildren="Signup with onfido"
                // unCheckedChildren="Normal signup"
              />
            </div>
            <div class='text-center mt-4'>
              Back to <a class='btn btn-link p-0 ml-1'>Sign In</a>
            </div>
          </div>
          {showOnfidoView && <div id='onfido-mount'></div>}
        </div>
      </section>
    </React.Fragment>
  );
}
