import React, { Component, useEffect } from 'react';
import NavbarCom from './NavbarCom';
import Banner from './Banner';
import Footer from './Footer';
import Info from './Info';

class Home extends Component {
  render() {
    return (
      <>
        <Banner />
        <Info />
      </>
    );
  }
}

export default Home;
