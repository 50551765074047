import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import oktaSignInConfig from '../OktaSignInConfig';
// import '../../../src/css/style-custom.css';
// import '../../../src/css/style.css';
import { Components } from 'antd/lib/date-picker/generatePicker';
import { phoneSchema } from '../validation/Validation';

const RegistrationSuccessful = () => {
  const navigate = useNavigate();

  return (
    <>
      <section class='section-login my-4'>
        <div class='login-container login-container-lg'>
          <div class='text-center py-4'>
            <svg
              width='57'
              height='56'
              viewBox='0 0 57 56'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M28.5002 28C34.9435 28 40.1668 22.7767 40.1668 16.3334C40.1668 9.89003 34.9435 4.66669 28.5002 4.66669C22.0568 4.66669 16.8335 9.89003 16.8335 16.3334C16.8335 22.7767 22.0568 28 28.5002 28Z'
                stroke='#41A958'
                stroke-width='4'
              ></path>
              <path
                d='M40.1666 51.3334H12.7873C12.1255 51.3335 11.4713 51.1929 10.868 50.9209C10.2647 50.6488 9.72622 50.2516 9.28819 49.7555C8.85016 49.2595 8.52265 48.6759 8.32739 48.0436C8.13213 47.4113 8.07359 46.7447 8.15565 46.088L9.06565 38.7987C9.27727 37.1052 10.1003 35.5474 11.3799 34.4181C12.6596 33.2889 14.3077 32.666 16.0143 32.6667H16.8333M37.8333 37.3334L43.0833 42L51.8333 32.6667'
                stroke='#41A958'
                stroke-width='4'
                stroke-linecap='round'
                stroke-linejoin='round'
              ></path>
            </svg>
            <h1 class='text-success mt-3'>Successfully Registered</h1>
            <p class='mb-5'>
              Congratulations, your account has been successfully created.
            </p>
            <a href='/' class='btn btn-primary px-4 mb-3'>
              Done
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegistrationSuccessful;
