const oktaSignInConfig = {
  clientId: '0oacgg8u1srON2d0G4x7',
  redirectUri: window.location.origin + '/login/callback',
  issuer: 'https://demo-beyondid.okta.com/oauth2/default',
  scopes: ['openid', 'profile', 'email'],
  useInteractionCodeFlow: true,
  // postLogoutRedirectUri: 'http://localhost:3000',
  postLogoutRedirectUri: 'https://node-react-onfido.herokuapp.com',
  baseUrl: 'https://demo-beyondid.okta.com',
  pkce: true,
};
export default oktaSignInConfig;
