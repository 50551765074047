import { Navigate, Outlet } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
const PrivateRoute = () => {
  const { authState } = useOktaAuth();
  console.log('authState is:-', authState);
  return authState?.isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to='/login/index' />
  );
};
export default PrivateRoute;
