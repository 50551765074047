import React from 'react'

const PaymyBill = () => {
    return (
        <>
            <section className="section-login">
                <div className="login-container login-container-lg px-0">
                    {/*<h1 className="text-dark mb-4">Pay as guest or Login to Pay</h1>*/}
                    <div className="d-flex">
                        <div className="login-panel py-4 border-right">
                            <div className="credential-title mb-2"><span>Pay As Guest</span></div>
                            {/*<p className="text-muted mb-4">Thank you for choosing to pay your bill online. Making an online payment is fast and easy. Get started by entering the account information as it appears on your statement below.</p>*/}
                            <div className="form-group">
                                <label>Account or Guarantor Number</label>
                                <input type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label>Last Name</label>
                                <input className="form-control" />
                            </div>
                            <div className="form-group">
                                <label>Date of Birth</label>
                                <div className="form-row">
                                    <div className="col"><input className="form-control" /></div>
                                    <div className="col-3"><input className="form-control" /></div>
                                    <div className="col-3"><input className="form-control" /></div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>ZIP Code<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" />
                            </div>
                            <div className="custom-control custom-checkbox mt-2">
                                <input type="checkbox" className="custom-control-input" id="terms" />
                                <label className="custom-control-label" htmlFor="terms">I agree to the <a href="#">Terms and Conditions</a>.</label>
                            </div>
                            <button className="btn btn-outline-primary text-uppercase my-4 btn-block">Continue</button>
                            {/*<p>Take advantage of paperless billing, multiple account management features, and flexible payment plan options.</p>
              <div className="text-center">
                <div className="btn btn-outline-dark text-uppercase">Create An Account</div>
              </div>*/}
                        </div>
                        <div className="login-panel py-4">
                            <div className="credential-title mb-4">Log in to<br /><span>Pay My Bill</span></div>
                            <div className="form-group">
                                <label>Username</label>
                                <input type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input type="password" className="form-control" />
                            </div>
                            <div className="d-flex justify-content-between">
                                <a href="#" className="text-primary">Forgot Username?</a>
                                <a href="#" className="text-primary">Forgot Password?</a>
                            </div>

                            <button className="btn btn-primary text-uppercase my-4 btn-block">Sign In</button>
                            <button className="btn btn-outline-primary text-uppercase my-4 btn-block">Create an account</button>
                            {/*<div className="text-center">
                <a href="#" className="text-muted">Make a one-time payment ></a>
              </div>*/}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PaymyBill;
