import React, { useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import oktaSignInConfig from '../OktaSignInConfig';
// import '../../../src/css/style-custom.css';
// import '../../../src/css/style.css';

const PayAsGuests = () => {
  //   const widgetRef = useRef();

  //   useEffect(() => {
  //     if (!widgetRef.current) {
  //       return false;
  //     }

  //     const widget = new OktaSignIn({
  //       baseUrl: oktaSignInConfig.baseUrl,
  //       clientId: oktaSignInConfig.clientId,
  //       redirectUri: oktaSignInConfig.redirectUri,
  //       logo: 'https://img.freepik.com/premium-psd/instagram-logo-social-media-3d_304434-558.jpg?w=2000',
  //     });

  //     widget
  //       .showSignInToGetTokens({ el: widgetRef.current })
  //       .then(onSuccess)
  //       .catch(onError);

  //     return () => widget.remove();
  //   }, [onSuccess, onError]);

  return (
    <>
      <section className='section-login'>
        <div className='login-container'>
          <div className='login-panel px-2'>
            <div className='credential-title mb-4'>
              Welcome to
              <br />
              <span>Pay My Bill</span>
            </div>
            <p className='text-muted'>
              Thank you for choosing to pay your bill online. Making an online
              payment is fast and easy. Get started by entering the account
              information as it appears on your statement below.
            </p>
            <div className='form-group'>
              <label>Account or Guarantor Number</label>
              <input type='text' className='form-control' />
            </div>
            <div className='form-group'>
              <label>Last Name</label>
              <input className='form-control' />
            </div>
            <div className='form-group'>
              <label>Date of Birth</label>
              <div className='form-row'>
                <div className='col'>
                  <input className='form-control' />
                </div>
                <div className='col-3'>
                  <input className='form-control' />
                </div>
                <div className='col-3'>
                  <input className='form-control' />
                </div>
              </div>
            </div>
            <div className='form-group'>
              <label>
                ZIP Code<span className='text-danger'>*</span>
              </label>
              <input type='text' className='form-control' />
            </div>
            <div className='custom-control custom-checkbox'>
              <input
                type='checkbox'
                className='custom-control-input'
                id='terms'
              />
              <label className='custom-control-label' for='terms'>
                The patient’s date of birth is different than mine.
              </label>
            </div>
            <div className='custom-control custom-checkbox mt-2'>
              <input
                type='checkbox'
                className='custom-control-input'
                id='terms1'
              />
              <label className='custom-control-label' for='terms1'>
                I agree to the <a href='#'>Terms of Us</a>.
              </label>
            </div>
            <button className='btn btn-primary text-uppercase mt-4 mb-3 btn-block'>
              Continue
            </button>
            <a
              href='/login/index'
              className='btn btn-outline-primary btn-block mb-3'
            >
              Cancel
            </a>
            <p>
              Take advantage of paperless billing, multiple account management
              features, and flexible payment plan options.
            </p>
            <div className='text-center'>
              <div className='btn btn-outline-dark text-uppercase'>
                Create An Account
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PayAsGuests;
