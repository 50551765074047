import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import oktaSignInConfig from '../OktaSignInConfig';
import { useOktaAuth } from '@okta/okta-react';
// import '../../../src/css/style-custom.css';
// import '../../../src/css/style.css';

const Profile = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      console.log('getToken values:-', oktaAuth.tokenManager.getTokens());
      // oktaAuth.tokenManager.getTokens().Profile
      oktaAuth
        .getUser()
        .then((info) => {
          console.log('getInfo', info);
          setUserInfo(info);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  console.log('userInfo is', userInfo);
  return (
    <>
      <section className='py-4 background-gradient-right'>
        <div className='container'>
          <div className='row'>

            <h2><Link className='text-primary-light mb-0 pr-5 ' to='/account/profile'>
              Profile
            </Link></h2>

            <h2><Link className='text-primary-light mb-0 pr-5 ' to='/account/token'>
              Token
            </Link>
            </h2>
          </div>
        </div>
      </section>
      <section className='section-register'>
        <div className='login-container'>
          <div className='login-panel'>
            {authState && authState.isAuthenticated && userInfo && (
              <form action=''>
                <div className='pb-4 mb-4 border-bottom'>
                  <label for='First Name' className='mb-0'>
                    First Name
                  </label>
                  <h3 className='mb-0 font-weight-bold'>{userInfo.given_name}</h3>
                </div>
                <div className='pb-4 mb-4 border-bottom'>
                  <label for='First Name' className='mb-0'>
                    Middle Name
                  </label>
                  <h3 className='mb-0 font-weight-bold'></h3>
                </div>
                <div className='pb-4 mb-4 border-bottom'>
                  <label for='First Name' className='mb-0'>
                    Last Name
                  </label>
                  <h3 className='mb-0 font-weight-bold'>{userInfo.family_name}</h3>
                </div>
                <div className='pb-4 mb-4 border-bottom'>
                  <label for='First Name' className='mb-0'>
                    Username
                  </label>
                  <h3 className='mb-0 font-weight-bold'>
                    {userInfo.preferred_username}
                  </h3>
                </div>
                <div className='pb-4 mb-4 border-bottom'>
                  <label for='First Name' className='mb-0'>
                    Email
                  </label>
                  <h3 className='mb-0 font-weight-bold'>{userInfo.email}</h3>
                </div>
                <div className='pb-4 mb-4 border-bottom'>
                  <label for='First Name' className='mb-0'>
                    Home phone
                  </label>
                  <h3 className='mb-0 font-weight-bold'>{userInfo.phoneNumber}</h3>
                </div>
                <div className='pb-4 mb-4'>
                  <label for='First Name' className='mb-0'>
                    Date of Birth
                  </label>
                  <h3 className='mb-0 font-weight-bold'>{userInfo.dateOfBirth}</h3>
                </div>
                <div className='pb-4 mb-4'>
                  <label for='First Name' className='mb-0'>
                    Gender
                  </label>
                  <h3 className='mb-0 font-weight-bold'>{userInfo.gender1}</h3>
                </div>
                <div className='pb-4 mb-4'>
                  <label for='First Name' className='mb-0'>
                    Address
                  </label>
                  <h3 className='mb-0 font-weight-bold'>{userInfo.addressLine1}</h3>
                </div>
                <div className='pb-4 mb-4'>
                  <label for='First Name' className='mb-0'>
                    City
                  </label>
                  <h3 className='mb-0 font-weight-bold'>{userInfo.city}</h3>
                </div>
                <div className='pb-4 mb-4'>
                  <label for='First Name' className='mb-0'>
                    State
                  </label>
                  <h3 className='mb-0 font-weight-bold'>{userInfo.state}</h3>
                </div>
                <div className='pb-4 mb-4'>
                  <label for='First Name' className='mb-0'>
                    Country
                  </label>
                  <h3 className='mb-0 font-weight-bold'></h3>
                </div>
                <div className='pb-4 mb-4'>
                  <label for='First Name' className='mb-0'>
                    Zip Code
                  </label>
                  <h3 className='mb-0 font-weight-bold'>{userInfo.zipcode}</h3>
                </div>
              </form>
            )}
            <div className='text-center'>
              <a href='/' className='btn btn-primary px-4'>
                Back
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
