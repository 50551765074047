import * as yup from 'yup';
//import { useFormik } from 'formik';

export const phoneSchema = yup.object().shape({
  phoneNumber: yup
    .number('Phone number is not valid')
    .required('Phone number is required')
    .min(1000000000, 'Phone number is not valid')
    .max(9999999999, 'Phone number is not valid'),

  checkbox: yup
    .bool()
    .oneOf(
      [true],
      'Please read and accept the terms and conditions to proceed with you.'
    ),
});

export const userSchema = yup.object().shape({
  firstName: yup
    .string('First Name is not valid')
    .required('First Name is required'),

  lastName: yup
    .string('Last Name is not valid')
    .required('Last Name is required'),

  dateOfBirth: yup
    .date()
    .max(new Date(), "Date of birth can't be in the future")
    .required('Date of birth is required')
    .nullable(),

  myChartId: yup
    .string('myChartId is not valid')
    .required('Username is required.'),

  password: yup.string().required('Password is required'),

  confirmPassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('password'), null], 'Password does not match'),

  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),

  addressLine1: yup.string().required('Address is required.'),

  city: yup.string().required('City is required'),

  state: yup.string().required('State is required'),

  zipcode: yup.number().required('Zipcode is required'),

  checkbox: yup
    .bool()
    .oneOf([true], 'Please read and accept the Consent to proceed with you.'),
});
