import { useState } from "react";
import { Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from "react-router-dom";

const AppointmentForm = ({lgShow,setLgShow}) => {

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [formError, setFormError] = useState(false);
    const [isBookingDetailVisible, setIsBookingDetailVisible] = useState(false);
    const [gender,setGender]=useState("");
    const [isCheck,setIsCheck]=useState(false)
    const [visitReason,setVisitReason] =useState("");
    const [appointmentType,setAppointmentType]=useState("")
    const [time,setTime]=useState("")

    const handleBook = (e)=>{
        e.preventDefault();
        if(!visitReason || !appointmentType || !time){
            console.log("error")
            setFormError(true);
            
        }
        else{
            const obj = {email,firstName,lastName,dateOfBirth,gender,visitReason,appointmentType,time}
            console.log(obj)
            setLgShow(false)
        }
    }

    const emailPattern = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    const isEmailValid = email ? emailPattern.test(email):false;
    const handleEmailChange = (event) => {
       setEmail(event.target.value);
    };


    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const handleDateOfBirthChange = (event) => {
        setDateOfBirth(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!isEmailValid || !email || !firstName || !lastName || !dateOfBirth || !isCheck ||!gender) {
            setFormError(true);
            return;
        }

        setFormError(false);
        setIsBookingDetailVisible(true);
    };

   

    const handleBack = () => {
        setIsBookingDetailVisible(false);
    };

    return (
        <div>

            <p className="text-muted mb-4">To make an appointment, we need to verify a few things.</p>
            {isBookingDetailVisible ? (
                <div>
                    <form onSubmit={handleBook}>
                        <div className="modal-body">
                            <div className="login-panel px-4">
                                
                                <section id="UserInfo" className="row" style={{ display: 'none' }}>
                                    <div className="col-md-12 form-group">
                                        <label>Email Address <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" placeholder="yourid@example.com" id="Email" name="Email" defaultValue />
                                        <span className="text-danger field-validation-valid" data-valmsg-for="Email" data-valmsg-replace="true" />
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <label>First Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" placeholder="First Name" id="FirstName" name="FirstName" defaultValue />
                                        <span className="text-danger field-validation-valid" data-valmsg-for="FirstName" data-valmsg-replace="true" />
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <label>Last Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" placeholder="Last Name" id="LastName" name="LastName" defaultValue />
                                        <span className="text-danger field-validation-valid" data-valmsg-for="LastName" data-valmsg-replace="true" />
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <label>Date of Birth <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" placeholder="YYYY-MM-DD" id="DateOfBirth" name="DateOfBirth" defaultValue />
                                        <span className="text-danger field-validation-valid" data-valmsg-for="DateOfBirth" data-valmsg-replace="true" />
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <label htmlFor="Gender">Gender <span className="text-danger">*</span></label>
                                        <div className="d-flex">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" defaultValue="Male" className="custom-control-input" id="Gender" name="Gender" />
                                                <label className="custom-control-label" htmlFor="customRadioInline1">Male</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" defaultValue="Female" className="custom-control-input" id="Gender" name="Gender" />
                                                <label className="custom-control-label" htmlFor="customRadioInline2">Female</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" defaultValue="Other" className="custom-control-input" id="Gender" name="Gender" />
                                                <label className="custom-control-label" htmlFor="customRadioInline3">Other</label>
                                            </div>
                                        </div>
                                        <span className="text-danger field-validation-valid" data-valmsg-for="Gender" data-valmsg-replace="true" />
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="terms" data-val="true" data-val-required="The Consent field is required." name="Consent" defaultValue="true" />
                                            <label className="custom-control-label" htmlFor="terms">
                                                I have read and accept the <a href="#">Terms</a>
                                                and <a href="#">Conditions</a>.
                                            </label>
                                            <span className="text-danger field-validation-valid" data-valmsg-for="Consent" data-valmsg-replace="true" />
                                        </div>
                                    </div>
                                </section>
                                <section id="BookingDetail" style={{ display: 'block' }} className="row">
                                    <div className="col-md-12 form-group">
                                        <label>What's the reason for your visit? <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" id="Appointment_Region" name="Appointment.Region" value={visitReason} onChange={(e)=>setVisitReason(e.target.value)}/>
                                        {formError && !visitReason && <span className="text-danger">Visit Reason Required</span>}
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <label>Choose the type of appointment <span className="text-danger">*</span></label>
                                        <div className="d-flex">
                                            <div className="custom-control custom-radio custom-control-inline d-flex align-items-center">
                                                <input type="radio" className="custom-control-input" defaultValue="In-Person" id="in-person" name="Appointment.AppointmentType" checked={appointmentType==="In-Person"} onClick={(e)=>setAppointmentType(e.target.value)}/>
                                                <label className="custom-control-label" htmlFor="in-person">In-person</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline  d-flex align-items-center">
                                                <input type="radio" className="custom-control-input" defaultValue="Video-Visit" id="video-visit" name="Appointment.AppointmentType" checked={appointmentType==="Video-Visit"} onClick={(e)=>setAppointmentType(e.target.value)} />
                                                <label  className="custom-control-label" htmlFor="video-visit">Video Visit</label>
                                            </div>
                                            {/* <div className="">
                                                <input type="radio" defaultValue="Video-Visit" className="" id="Appointment_AppointmentType" name="Appointment.AppointmentType" />
                                                <label className="custom-control-label" htmlFor="customRadioInline2">Video Visit</label>
                                            </div> */}
                                        </div>
                                        {formError && !appointmentType && <span className="text-danger">Appointment Type Required</span>}

                                    </div>
                                    <div className="col-md-12 form-group">
                                        <label>Select an available time</label>
                                        <input type="date" className="form-control" data-val="true" value={time} onChange={(e)=>setTime(e.target.value)} data-val-required="The AvailableTime field is required." id="Appointment_AvailableTime" name="Appointment.AvailableTime" defaultValue />
                                        {formError && !time && <span className="text-danger">Time Required</span>}

                                    </div>

                                </section>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button id="continue" type="button" style={{ display: 'none' }} className="btn btn-primary btn-block text-uppercase">
                                Continue
                            </button>
                            <button id="Submit" type="submit" style={{ display: 'block' }} className="btn btn-primary btn-block text-uppercase">
                                Book an Appointment
                            </button>
                            {/* <Button className="btn btn-primary btn-block text-uppercase"> <Link className="nav-link" to="/Findadoctor#">Book an Appointment</Link></Button> */}
                            <button onClick={handleBack} id="back" type="button" style={{ display: 'block' }} className="btn  btn-block text-uppercase">
                                back
                            </button>
                        </div>
                        <input name="__RequestVerificationToken" type="hidden" defaultValue="CfDJ8PaaAgmXYfdEhDkbN3zKdo7wfkx6apeud68Ro-rypc6xNAtXJ8e2bPKu00VGk8fD25ZVViQR5NTNuEJ5QS3-TgnlppuwGMROalxfYuMbbRn_WyBJtmD7mo2lUbirjm7egmF4HYqKgXswbqqnsPK-hVs" /><input name="Consent" type="hidden" defaultValue="false" /></form>

                </div>
            ) : (

                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="Email">Email  <span className="text-danger">*</span></label>
                        <input  id="Email" className="form-control" value={email} onChange={handleEmailChange} />
                        {formError && !email && <span className="text-danger">Email is required.</span>}
                        {formError && !isEmailValid && email &&  <span className="text-danger">Email is invalid.</span>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="FirstName">First Name <span className="text-danger">*</span></label>
                        <input type="text" id="FirstName" className="form-control" value={firstName} onChange={handleFirstNameChange} />
                        {formError && !firstName && <span className="text-danger" >First Name is required.</span>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="LastName">Last Name <span className="text-danger">*</span></label>
                        <input type="text" id="LastName" className="form-control" value={lastName} onChange={handleLastNameChange} />
                        {formError && !lastName && <span className="text-danger">Last Name is required.</span>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="DateOfBirth">Date Of Birth <span className="text-danger">*</span></label>
                        <input type="date" id="DateOfBirth" className="form-control" value={dateOfBirth} onChange={handleDateOfBirthChange} />
                        {formError && !dateOfBirth && <span className="text-danger">Date Of Birth is required.</span>}
                    </div>


                    <div class="form-group">
                        <Form.Group controlId="gender">
                            <Form.Label>Gender <span className="text-danger">*</span></Form.Label>
                            <div className="d-flex">
                                <Form.Check inline type="radio" id="male" name="gender" label="Male" value="Male" onClick={(e)=>setGender(e.target.value)} checked={gender=="Male"}/>
                                <Form.Check inline type="radio" id="female" name="gender" label="Female" value="Female" onClick={(e)=>setGender(e.target.value)} checked={gender=="Female"}/>
                                <Form.Check inline type="radio" id="other" name="gender" label="Other" value="Other"  onClick={(e)=>setGender(e.target.value)} checked={gender=="Other"}/>
                            </div>
                            {formError && !gender && <span className="text-danger">Gender is required.</span>}

                        </Form.Group>

                    </div>
                        <div className=" form-group">
                            <div className="custom-control custom-checkbox TNC-cb d-flex align-items-center">
                                <input asp-for="Consent" type="checkbox" className="custom-control-input" id="terms" onClick={(e)=>setIsCheck(!isCheck)} checked={isCheck}/>
                                <label className="custom-control-label d-flex" htmlFor="terms"><span className="TNC-LABEL">I have read and accept the<a href="#" className="mx-1">Terms</a> 
                                     and<a href="#" className="ml-1">Conditions</a>.</span> 
                                </label>
                                {/* <span asp-validation-for="Consent" className="text-danger" /> */}
                            </div>
                            {formError && !isCheck && <span className="text-danger">Please accept the terms and conditions.</span>}
                        </div>
                        <section id="Booking
                        Detail" style={{ display: 'none' }} className="row">
                            <div className="col-md-12 form-group">
                                <label>What's the reason for your visit? <span className="text-danger">*</span></label>
                                <input asp-for="Appointment.Region" type="text" className="form-control" onChange={(e)=>setVisitReason(e.target.value)} value={visitReason}/>
                            </div>
                            <div className="col-md-12 form-group">
                                <label>Choose the type of appointment <span className="text-danger">*</span></label>
                                <div className="d-flex">
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input asp-for="Appointment.AppointmentType" id="in-person" type="radio" defaultValue="In-Person" className="custom-control-input" />
                                        <label className="custom-control-label" htmlFor="in-person">In-person</label>
                                    </div>
                                    <div className="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="video-visit" defaultValue="Video-Visit" className="custom-control-input" />
                                        <label className="custom-control-label" htmlFor="video-visit">Video Visit</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 form-group">
                                <label>Select an available time</label>
                                <input asp-for="Appointment.AvailableTime" type="date" className="form-control" />
                            </div>
                        </section>
                    <button type="submit" className="btn btn-primary btn-block text-uppercase">Continue</button>
                </form>

            )}
        </div>
    );
}
export default AppointmentForm;
