 const data = [
  {
    id: 1,
    name: "Authur Miles",
    rating: 4.5,
    Title: "Radiologist",
    url:'https://images.unsplash.com/photo-1612276529731-4b21494e6d71?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
  },
  {
    id: 2,
    name: "Usman Yousaf",
    rating: 4.6,
    Title: "Nurse Practitioner",
    url:'https://images.unsplash.com/photo-1594824476967-48c8b964273f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'
  },
  {
    id: 3,
    name: "Rian Ramirez",
    rating: 5,
    Title: "Nurse Practitioner",
    url:'https://images.unsplash.com/photo-1560250097-0b93528c311a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'
  },
  {
    id: 4,
    name: "Foto Sushi",
    rating: 4.5,
    Title: "Radiologist",
    url:'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
  },
  {
    id: 5,
    name: "Jester Dolam",
    rating: 4.5,
    Title: "Nurse Practitioner",
    url:'https://images.unsplash.com/photo-1605684954998-685c79d6a018?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
  },
  {
    id: 6,
    name: "Yan Ramirez",
    rating: 5,
    Title: "Internist",
    url:'https://images.unsplash.com/photo-1560250097-0b93528c311a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'
  },
  {
    id: 7,
    name: "Siednji Leon",
    rating: 4.6,
    Title: "Dentist",
    url:'https://images.unsplash.com/photo-1623854767648-e7bb8009f0db?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'
  },
  {
    id: 8,
    name: "Vicky Haldynets",
    rating: 4.5,
    Title: "Radiologist",
    url:'https://images.unsplash.com/photo-1642541724244-83d49288a86b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'
  },
];
export default data;