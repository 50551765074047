import React from 'react'
import Footer from '../Footer';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import AppointmentForm from './AppointmentForm';
import data from './DoctorDetails'
const Findadoctor = (props) => {
    const [lgShow, setLgShow] = useState(false);
    return (
        <>
            <div className="container py-5">
                <div className="row justify-content-center mb-4">
                    <div className="col-md-8">
                        <div className="form-inline">
                            <label className="col-auto pr-0 text-primary" htmlFor>Find a doctor</label>
                            <div className="col">
                                <input className="form-control w-100" type="text" placeholder="Search doctor, procedure, condition... " />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        data.map((doctor,index)=><div className="col-sm-6 col-md-4 col-lg-3 my-3" key={index}>
                        <div className="card text-center">
                            <div className="card-body">
                                <img className="img-circle" src={doctor.url} alt="text" />
                                <div className="text-center mt-4 d-flex  justify-content-center">
                                    <svg width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.4628 5.70694L9.99972 5.05831L8.0046 1.01358C7.95011 0.902839 7.86046 0.81319 7.74972 0.758698C7.47198 0.621589 7.13448 0.735846 6.99562 1.01358L5.0005 5.05831L0.537414 5.70694C0.414367 5.72452 0.301867 5.78253 0.215734 5.87042C0.111604 5.97744 0.0542242 6.12143 0.0562018 6.27074C0.0581795 6.42006 0.119353 6.56247 0.226281 6.66671L3.45538 9.81495L2.69249 14.2605C2.6746 14.3639 2.68605 14.4702 2.72552 14.5675C2.765 14.6647 2.83094 14.7489 2.91585 14.8106C3.00077 14.8723 3.10127 14.9089 3.20595 14.9164C3.31063 14.9239 3.41531 14.9018 3.50812 14.8528L7.50011 12.754L11.4921 14.8528C11.6011 14.9108 11.7276 14.9302 11.8489 14.9091C12.1548 14.8564 12.3605 14.5663 12.3077 14.2605L11.5448 9.81495L14.7739 6.66671C14.8618 6.58057 14.9198 6.46807 14.9374 6.34503C14.9849 6.03741 14.7704 5.75264 14.4628 5.70694Z" fill="#E69700" />
                                    </svg>
                                    <span className="text-muted font-weight-bold ml-1">4.6</span>
                                </div>
                                <h4 className="text-primary mb-1">Dr. {doctor.name}</h4>
                                <p className="text-muted">{doctor.Title}</p>
                                <button className="btn btn-outline-primary btn-block btm-sm mt-4" onClick={() => setLgShow(true)}>
                                    Book an Appointment
                                </button>
                            </div>
                        </div>
                    </div>)
                    }
                </div>
            </div>
            <Modal
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Book Appointment
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AppointmentForm lgShow={lgShow} setLgShow={setLgShow}/>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Findadoctor