import { useOktaAuth } from '@okta/okta-react';
import Footer from '../Footer';
import OktaSignInWidget from '../Login/OktaSignInWidget';
import oktaSignInConfig from '../OktaSignInConfig';
const Auth = () => {

    const { oktaAuth, authState } = useOktaAuth();

    const onSuccess = (tokens) => {
        oktaAuth.handleLoginRedirect(tokens);
    };

    const onError = (err) => {
        console.log('error logging in', err);
    };


    if (!authState) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <OktaSignInWidget onSuccess={onSuccess} onError={onError} />

        </>
    )

}

export default Auth